"use client"
import Image from "next/image";
import React, { useEffect, useRef, useState } from "react";
import styles from "./accordion.module.scss";

export interface Props {
    title: string;
    translations: any;
    children: React.ReactNode;
    isFaq?: boolean;
    className?: string;
    useWhiteIcon?: boolean;
    titleElement?: React.ReactNode;
    useChevrons?: boolean;
    onExpandCallback?: (isExpanding: boolean) => void;
    isExpandedOnLoad?: boolean;
    forceToggle?: boolean | undefined;
}

export default function Accordion({ title, translations, children, className, titleElement, onExpandCallback, isFaq = false, useWhiteIcon = false, useChevrons = true, isExpandedOnLoad = false, forceToggle }: Props) {
    const [isExpanded, setIsExpanded] = useState(isExpandedOnLoad);
    const contentRef = useRef<HTMLDivElement>(null);
    const contentId = `accordion-content-${title.replace(/\s+/g, '-').toLowerCase()}`;

    useEffect(() => {
        if (isExpandedOnLoad && isExpanded && onExpandCallback) {
            onExpandCallback(isExpandedOnLoad);
        }
    }, [isExpandedOnLoad, isExpanded, onExpandCallback]);

    const toggleExpand = () => {
        setIsExpanded((prev) => {
            const newIsExpanded = !prev;
            if (onExpandCallback) {
                onExpandCallback(newIsExpanded);
            }
            return newIsExpanded;
        });
    };

    useEffect(() => {
        if (forceToggle !== undefined) {
            setIsExpanded(forceToggle);
        }
    }, [forceToggle]);

    return (
        <div className={`w-full ${className}`}>
            <div
                role="button"
                aria-expanded={isExpanded}
                aria-controls={contentId}
                className="cursor-pointer flex items-center justify-between p-4"
                onClick={toggleExpand}
            >
                {titleElement && (
                    <div className="flex-grow">
                        {titleElement}
                    </div>
                )}
                {(!titleElement && isFaq) && (
                    <h3>{title}</h3>
                )}
                {(!titleElement && !isFaq) && (
                    <div className="flex-grow text-normal font-bold mb-0-i">{title}</div>
                )}

                {useChevrons ? (
                    <Image
                        src={isExpanded ? `/assets/img/icons/chevron-up${useWhiteIcon ? "_white" : ""}.svg` : `/assets/img/icons/chevron-down${useWhiteIcon ? "_white" : ""}.svg`}
                        width={16}
                        height={16}
                        alt={isExpanded ? `${translations.general.collapse}` : `${translations.general.expand}`}
                        role="button"
                        aria-expanded={isExpanded}
                        aria-controls={contentId}
                    />
                ) : (
                    <Image
                        src={isExpanded ? `/assets/img/icons/icon_minus${useWhiteIcon ? "_white" : ""}.svg` : `/assets/img/icons/icon_plus${useWhiteIcon ? "_white" : ""}.svg`}
                        width={16}
                        height={16}
                        alt={isExpanded ? `${translations.general.collapse}` : `${translations.general.expand}`}
                        role="button"
                        aria-expanded={isExpanded}
                        aria-controls={contentId}
                    />
                )}
            </div>
            <div
                id={contentId}
                ref={contentRef}
                className={`${styles.contentContainer} ${isExpanded ? styles.expanded : ''}`}
            >
                <div className="p-4 pt-0">
                    {children}
                </div>
            </div>
        </div>
    );
}
